<template>
  <footer class="footer__area-6">
    <div class="container g-0 line_4">
      <div class="line-col-4">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>

      <div class="row">
        <div class="col-xxl-12">
          <div class="footer__top-6 pt-150 pb-140">
            <div class="footer__item-6">
              <img
                class="footer__logo"
                src="@/assets/images/logo/logo-v2.png"
                width="140px"
                alt="Footer Logo"
              />
              <p>
                Penyimpanan Data Sumber Daya Air Terpusat dan Terintegrasi
                dengan Balai Besar / Balai Wilayah Sungai dan Sistem Informasi
                Direktorat Jenderal Sumber Daya Air serta Pusat Data Kementerian
                PUPR.
              </p>
              <ul class="footer__social-6">
                <li>
                  <a href="#"
                    ><span><i class="fa-brands fa-facebook-f"></i></span
                  ></a>
                </li>
                <li>
                  <a href="#"
                    ><span><i class="fa-brands fa-twitter"></i></span
                  ></a>
                </li>
                <li>
                  <a href="#"
                    ><span><i class="fa-brands fa-instagram"></i></span
                  ></a>
                </li>
                <li>
                  <a href="#"
                    ><span><i class="fa-brands fa-linkedin"></i></span
                  ></a>
                </li>
              </ul>
            </div>

            <div class="footer__item-6">
              <h2 class="footer__item-title">Informasi</h2>
              <ul class="footer__link-6">
                <li><a href="about.html">About Company</a></li>
                <li><a href="career.html">Career</a></li>
                <li><a href="portfolio.html">Case Study</a></li>
                <li><a href="#">Unloack System</a></li>
                <li><a href="#">Blueprint</a></li>
              </ul>
            </div>

            <div class="footer__item-6">
              <h2 class="footer__item-title">Kontak Kami</h2>
              <ul class="footer__info-6">
                <li>
                  Jl. Ir. H. Juanda No.193, Dago, Kecamatan Coblong, Kota
                  Bandung, Jawa Barat 40135
                </li>
                <li>
                  <a href="tel:081234567" class="phone">
					081234567
                  </a>
                </li>
                <li>
                  <a href="mailto:mail@siatab.com"
                    >mail@siatab.com</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="footer__btm-6">
            <div class="row">
              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-5">
                <div class="footer__copyright-6">
                  <p>©{{ currentYear }} | Kementrian PUPR</p>
                </div>
              </div>
              <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-7">
                <div class="footer__nav">
                  <ul class="footer-menu menu-anim">
                    <li><a href="#">Tentang Kami</a></li>
                    <li><a href="#">Kontak</a></li>
                    <li><a href="#">FAQs</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>