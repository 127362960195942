<template>
  <div>
    <button class="scroll-top" v-if="showBackToTop" @click="scrollToTop">
      <i class="fa-solid fa-arrow-up"></i>
    </button>

    <Header :status="status" @toggle-canvas="toggleOffCanvas"/>

	<OffCanvas />

    <div id="smooth-wrapper">
      <div id="smooth-content">
        <main>
          <div class="home-wrapper-4">
            <Hero />
            <Infrastructure />
          </div>
        </main>
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/landing/assets/css/bootstrap.min.css";
import "@/assets/landing/assets/css/all.min.css";
import "@/assets/landing/assets/css/master.css";

import Header from "@/views/components/landing/Header.vue";
import Hero from "@/views/components/landing/Hero.vue";
import Infrastructure from "@/views/components/landing/Infrastructure.vue";
import Footer from "@/views/components/landing/Footer.vue";
import OffCanvas from "@/views/components/landing/OffCanvas.vue";

export default {
  components: {
    Header,
	Hero,
    Infrastructure,
	OffCanvas,
	Footer
  },
  data() {
    return {
      showBackToTop: false,
	  status: false,
    };
  },
  name: "LandingPage",
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.showBackToTop = window.scrollY > 300;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    submitForm() {
      alert("Terima kasih telah menghubungi kami!");
    },
	toggleOffCanvas(newStatus) {
      this.status = newStatus;
	  alert(newStatus)
    }
  },
};
</script>


<style>
.header__area-3 {
  background: #f8f8f8;
}
</style>